import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Heading from "../components/sub-page/heading"
import SubFooter from "../components/sub-page/sub-footer"
import ITHealthcareBackground from "../images/it-health/Cyber-Security.png"
import Firewall from "../images/it-health/firewall.png"
import Email from "../images/it-health/email.jpg"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const ItHealthcare = () => (
  <Layout>
    <Seo title="IT Health" />
    <Heading />
    <div
      className="w-screen bg-sun py-16 mt-24 mb-8"
      style={{
        position: `relative`,
        left: `50%`,
        right: `50%`,
        marginLeft: `-50vw`,
        marginRight: `-50vw`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: "1536px",
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <div className="grid grid-cols-1 md:grid-cols-1">
          <div>
            <h2 className="text-6xl text-white pb-2 text-center">
              What is IT Health Care?
            </h2>
            <p className="text-white mx-auto mt-4 text-xl max-w-2xl text-center">
              IT Health Care is a combination of all services. From cyber
              security to device management and more our IT Health Care service
              provides everything you need to run a successful operation.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="my-24 mx-auto px-6 md:px-10">
      <div>
        <h2 className="text-6xl text-gray-900 pb-2">What's included?</h2>
        <p className="text-xl max-w-6xl">
          Everything you need. No more worrying about tech!
        </p>
      </div>
      <div className="rounded-xl border-4 border-gray-100 my-10 py-6 mx-auto px-6 transition duration-300 ease-in-out hover:bg-gray-100 md:px-10">
        <div>
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-4xl pb-4">
                Virtual Chief Technology Officer
              </h2>
              <h3 className="pb-6 text-2xl">vCTO</h3>
            </div>
          </div>
          <div>
            <p className="text-xl max-w-6xl">
              Every one of our customers will have the ability to leverage the
              knowledge and experience of a vCTO – without the cost of a
              full-time executive. Your vCTO will act as the point person for
              any of your technology needs and then advise you on the best
              course of action…for you. This means your business receives the
              technical strategy, guidance and leadership needed to keep your IT
              costs in check while also developing synergies between technology,
              internal processes, and business objectives to enable your
              business to continue to grow, and, if you do not have a technology
              strategy, we will work with you to create one.
            </p>
            <p className="text-xl pt-4 max-w-6xl font-bold">
              Whether technology is at the center of your business or plays a
              supporting role, your vCTO is there to help implement your next
              step. Just let them know and leave the rest to us!
            </p>
          </div>
        </div>
      </div>
      <div className="rounded-xl border-4 border-gray-100 my-10 py-6 mx-auto px-6 transition duration-300 ease-in-out hover:bg-gray-100 md:px-10">
        <div>
          <h2 className="text-4xl pb-8">Desktop/Server Management</h2>
          <p className="text-xl max-w-6xl">
            User workstations and servers are at the forefront of your
            operational efficiency. i360 focuses on resource-monitoring and
            patch management, as well as keeping them secure. Using
            state-of-the-art tools, we know when a processor is over-utilized,
            or your computer is out of memory or disk space. Based on the data
            we collect; our team determines if an issue is an isolated incident
            or ongoing problem.
          </p>
          <p className="text-xl pt-4 max-w-6xl">
            Security management of workstations and servers are vital to your
            operational success. Users have access to important applications and
            shared files, making system vulnerabilities open to exploitation
            from any malicious attack. i360 scans for those vulnerabilities and
            work to remediate any that are found.
          </p>
          <p className="text-xl pt-4 max-w-6xl">
            i360 provides anti-virus/anti-malware software, along with certified
            resources to manage these products. While many companies would stop
            there, i360 takes it further: included with every device, we offer
            our Managed Threat Response Service. Just being notified of a threat
            isn’t protection – it’s a starting point. i360 is armed with a 24/7
            team of experts who:
          </p>
          <ul className="text-xl list-disc mt-4 ml-8">
            <li>
              Proactively look for and validate potential threats and incidents
            </li>
            <li>
              Use all available information to determine the scope and severity
              of threats
            </li>
            <li>Apply appropriate business context for valid threats</li>
            <li>
              Initiate actions to remotely disrupt, contain and neutralize
              threats
            </li>
            <li>
              Provide actional advice for addressing the root cause of recurring
              incidents
            </li>
          </ul>
        </div>
      </div>
      <div className="rounded-xl border-4 border-gray-100 my-10 py-6 mx-auto px-6 transition duration-300 ease-in-out hover:bg-gray-100 md:px-10">
        <div>
          <h2 className="text-4xl pb-8">Backup & Disaster Recovery</h2>
          <p className="text-xl max-w-6xl">
            Losing data, a hard drive, server, or your office can be
            devastating.
          </p>
          <p className="text-xl pt-4 max-w-6xl">
            Over 90% of businesses that lose their network for more than 10 days
            due to a disaster file for bankruptcy within a year. We implement a
            combination of best practice approaches for delivering reliable
            backup and recovery solutions. i360 offers:
          </p>
          <ul className="text-xl list-disc mt-2 ml-8">
            <li>Minimized risks and liability associated with data loss</li>
            <li>
              Managed solutions that free staff from managing external hard
              drives, tapes, and other back solutions
            </li>
            <li>
              Short recovery time due to loss of data, hardware failures or
              disaster
            </li>
            <li>
              Availability to store backup data in offsite locations, which adds
              an additional layer of protection from data loss
            </li>
          </ul>
          <p className="text-xl pt-4 max-w-6xl">
            When small business owners or managers are asked if they have a
            reliable backup system in place, most believe they do. However,
            those same people don’t know if, or how often, that backup system is
            tested. Unfortunately, in too many cases, you don’t know your backup
            technology is outdated until it’s too late. That’s why we focus on
            true disaster recovery planning. i360 offers enterprise-class
            protection at small business cost. We will provide you a local
            backup system for simple file restores and push your latest backup
            to an encrypted offsite facility – just in case something
            significant happens. No tapes or hard drives for you to manage or
            think about; you don’t need to do anything. Think of it as an
            electronic security blanket – you know that when you wake up in the
            morning, everything will be good.
          </p>
          <p className="text-xl pt-4 max-w-6xl">
            Most importantly, we certify your backup system is working as
            designed. We scrutinize every backup log to guarantee successful
            results. If a local backup fails, we fix it. If an offsite data
            transfer doesn’t complete, we run a second transmit. Finally, we
            test your offsite backups regularly to ensure you’ll have a virtual
            server on which to run your business should the unthinkable happen,
            giving you peace of mind.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-3 mt-10 text-white">
        <div
          className="md:row-span-3 h-full group flex flex-col justify-end bg-cover bg-center bg-no-repeat bg-insight rounded-lg p-8 shadow-xl"
          style={{
            backgroundImage: `url(${ITHealthcareBackground})`,
            minHeight: `250px`,
          }}
        >
          <AnchorLink to="/security#cyber">
            <div className="transition ease-in-out duration-500 bg-insight bg-opacity-75 p-10 rounded-lg group-hover:bg-opacity-100">
              <h2 className="text-4xl font-heading font-bold pb-2">
                Cybersecurity
              </h2>
              <div className="flex flex-row pt-4">
                <p className="pr-2 font-body font-bold">Learn More</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </div>
            </div>
          </AnchorLink>
        </div>
        <div
          className="h-full group flex flex-col justify-end bg-cover bg-center bg-no-repeat bg-insight rounded-lg p-8 shadow-xl"
          style={{ backgroundImage: `url(${Firewall})`, minHeight: `250px` }}
        >
          <AnchorLink to="/security#firewall">
            <div className="transition ease-in-out duration-500 bg-insight bg-opacity-75 p-10 rounded-lg group-hover:bg-opacity-100">
              <h2 className="text-4xl font-heading font-bold pb-2">
                Firewall Management
              </h2>
              <div className="flex flex-row pt-4">
                <p className="pr-2 font-body font-bold">Learn More</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </div>
            </div>
          </AnchorLink>
        </div>
        <div
          className="h-full group flex flex-col justify-end bg-cover bg-center bg-no-repeat bg-insight rounded-lg p-8 shadow-xl"
          style={{ backgroundImage: `url(${Email})`, minHeight: `250px` }}
        >
          <AnchorLink to="/security#email">
            <div className="transition ease-in-out duration-500 bg-insight bg-opacity-75 p-10 rounded-lg group-hover:bg-opacity-100">
              <h2 className="text-4xl font-heading font-bold pb-2">
                Email Protection
              </h2>
              <div className="flex flex-row pt-4">
                <p className="pr-2 font-body font-bold">Learn More</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </div>
            </div>
          </AnchorLink>
        </div>
        <div
          className="md:row-span-2 md:col-span-2 h-full group flex flex-col justify-end bg-cover bg-center bg-no-repeat bg-insight rounded-lg p-8 shadow-xl"
          style={{
            backgroundImage: `url(${ITHealthcareBackground})`,
            minHeight: `250px`,
          }}
        >
          <AnchorLink to="/security#network">
            <div className="transition ease-in-out duration-500 bg-insight bg-opacity-75 p-10 rounded-lg group-hover:bg-opacity-100">
              <h2 className="text-4xl font-heading font-bold pb-2">
                Network Security
              </h2>
              <div className="flex flex-row pt-4">
                <p className="pr-2 font-body font-bold">Learn More</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </div>
            </div>
          </AnchorLink>
        </div>
      </div>
    </div>
    <SubFooter />
  </Layout>
)

export default ItHealthcare
