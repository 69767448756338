import * as React from "react"
import ITHealthcareBackground from '../../images/services/ithealthcare-background.jpg'

export default function Heading() {
  return (
          <div className="rounded-xl shadow-xl py-24 mx-auto px-6 sm:py-12 md:py-36 md:px-10" style={{backgroundImage: `url(${ITHealthcareBackground})`}}>
            <div className="sm:text-left lg:text-left">
              <h1 className="text-4xl tracking-tight font-heading font-extrabold text-white sm:text-5xl md:text-6xl lg:text-8xl">
                 What <span className="text-sun">you</span> don't<br/>know, can <span className="text-sun">cripple</span> you.
              </h1>
              <p className="mt-3 text-base font-body text-white sm:mt-5 sm:text-lg sm:max-w-2xl sm:mx-0 md:mt-5 md:text-xl lg:mx-0">
                We anticipate your every need to make sure your IT infrastructure is always there for your business.  
              </p>
            </div>
          </div>
  )
}